/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Cny = {
		// All pages
		'common': {

			triggerNiceScroll: function() {
				var $container = $('.js-trigger-ns');
				if (!$container.length) {
					return;
				}
				$container.niceScroll(
					{
						horizrailenabled:false,
						autohidemode: false

					});
				//TODO made need to use this
				//$("<your div>").getNiceScroll().resize();
			},
			triggerAlert: function() {
				var name = 'alertModal';
				var value = localStorage.getItem(name);

				if(value == null) {
					$('#modal-introjs').modal('show');
				}
				$('.js-trigger-ls').on('click', function () {
					localStorage.setItem(name, 'alert disabled');
				});
			},
			triggerTabHash: function() {
				// Javascript to enable link to tab
				var url = document.location.toString();
				if (url.match('#')) {
					$('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
				}

				// Change hash for page-reload
				$('.nav-tabs a').on('shown.bs.tab', function (e) {
					//window.location.hash = e.target.hash; //causes the page to scroll/jump to the element

					history.replaceState({},'',e.target.hash);
				});

			},
			init: function() {
				$( document ).ajaxStart(function() {
					//console.log("AJAX start");
					$.ajax({
						url: '/admin/auth/check',
						type: 'GET',
						global: false,
						success: function(data) {
							if(!data.isLoggedIn) {
								xhr.abort();

								location.reload();
							}
						},
						dataType: 'json'
					});
				}).ajaxError(function(event, jqxhr, settings, thrownError) {
					alert("An error occured. Please try again or contact support.");
				});

				// JavaScript to be fired on all pages
				$("body").on("click", ".modal-trigger", function(){
					var el = $(this);
					var proceed = true;

					if (el.hasClass("confirm")) {
						proceed = confirm("Are you sure you want to delete this entry? It cannot be undone!");
					}

					if (proceed) {
						var target = el.data('target');
						if (target === '' || typeof target === 'undefined') target = '#modal';

						var url = el.data('url');
						if (url === '' || typeof url === 'undefined') url = el.attr("href");

						if (url !== '') {
							$.get(url, function (data) {
								$(target + " .modal__title").text(el.data('title'));
								$(target + " .modal__body").html(data);

								$(target).modal({backdrop:'static'}).modal('show');
							});
						}

						/*
						//TODO - revisit NiceScroll - it's throwing errors on modal close
						$(target).on('show.bs.modal', function (e) {
							// do something...
							Cny.common.triggerNiceScroll();
						});

						$(target).on('hide.bs.modal', function (e) {
							// do something...
							$('.js-trigger-ns').niceScroll().remove();
						});
						*/
					}

					return false;
				});
				$('#modal').on('hidden.bs.modal', function (e) {
					$('#modal video').attr("src", "");
					//remove content since iframe video is a problem with above code
					$("#modal iframe").attr("src", "");
				});

				$("body").on("click", ".modal-filemanager", function(){
					var el = $(this);

					var url = el.data('url');
					if (url === '' || typeof url === 'undefined') url = el.attr("href");

					if (url !== '') {
						$.get( url, function( data ) {
							$("#modal-filemanager .modal__body iframe").attr('src',url);

							$("#modal-filemanager").modal('show');
						});
					}else {
						alert( "An error occured. Please try again or contact support." );
					}

					return false;
				});

				$('#modal-media-manager').on('hide.bs.modal', function(){
					$('#modal-media-manager .media-manager').mediamanager('destroy');
					$("#modal-media-manager .modal__body").html('');
				});

				$("body").on("click", ".modal-media-manager", function(){
					var el = $(this);
					$.get('/admin/media-manager/manager-type', function(data) {
						if (data.default == 'mediamanager') {
							var url = el.data('url');
							if (url === '' || typeof url === 'undefined') url = el.attr("href");

							if (url !== '') {
								$.get( url, function( data ) {
									$("#modal-media-manager .modal__body").html(data);

									$("#modal-media-manager").modal('show');
								});
							}else {
								alert( "An error occured. Please try again or contact support." );
							}
						}
						else {
							var url = el.data('url');
							if (url === '' || typeof url === 'undefined') url = el.attr("href");

							if (url !== '') {
								$("#modal-filemanager .modal__body iframe").attr('src',url+"&manager_type="+data.default);

								$("#modal-filemanager").modal('show');
							}
							else {
								alert( "An error occured. Please try again or contact support." );
							}
						}
					});
					return false;
				});

				$("body").on("click", ".confirm-delete", function(){
					return confirm("Are you sure you want to delete this entry? It cannot be undone!");
				});

				window.cny.wysiwyg();

				$("body").on("click", "[data-toggle='collapse']", function() {
					$(this).find('.fa').toggleClass('fa-plus-square fa-minus-square');
				});

				//filemanager
				$('#modal-filemanager').on('show.bs.modal', function (e) {
					$("#modal").removeClass("modal"); //work around for tinyMCE 5 closing #modal.modal ?
				}).on('hidden.bs.modal', function (e) {
					$(".filemanager-image").each(function(){
						var id = $(this).data("image_src");
						var settings = $(this).data("settings");

						if ($(id).val()) {
							if ($(id).val().indexOf("/userFiles/uploads/") == -1) {
								$(id).val("/userFiles/uploads/" + $(id).val());
							}
							$(this).attr("src", $(id).val()).show();

							$(settings).show();
						}
					});
					$("#modal").addClass("modal"); //work around for tinyMCE 5 closing #modal.modal ?
				});

				Cny.common.triggerTabHash();

				//select embed code
				$('.selectable').click(function(){
					if (document.body.createTextRange) {
						const range = document.body.createTextRange();
						range.moveToElementText(this);
						range.select();
					}else if (window.getSelection) {
						const selection = window.getSelection();
						const range = document.createRange();
						range.selectNodeContents(this);
						selection.removeAllRanges();
						selection.addRange(range);
					}
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				if ($('ul.errors li').length > 0) {
					$('html,body').stop().animate({
						scrollTop: $('ul.errors li').offset().top - 50
					}, 1000);
				}

				window.cny.videoPlayer();
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				//Cny.common.triggerAlert();
				//window.cny.triggerIntroJS();

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// CMS Page Editor
		'page_editor': {
			sectionSortable: function() {
				var controller_url = ($("body").hasClass("dlp"))?"dlp-cms":"cms";

				//sort sections
				$( ".section-list" ).sortable({
					helper: 'clone',
					handle: ".sort-handle",
					placeholder: "ui-state-highlight",
					'ui-floating': 'auto',
					'start': function (event, ui) {
						$(".ui-state-highlight").height(ui.item.height());
					},
					'sort': function (event, ui) {
						ui.helper.css({'top': ui.position.top + 'px'});
					},
					'update': function (event, ui) {
						$.post('/admin/'+controller_url+'/sort-section', $(this).sortable('serialize'), function (data) {
							//
						});
					}
				});
			},
			rowSortable: function() {
				var controller_url = ($("body").hasClass("dlp"))?"dlp-cms":"cms";

				//sort rows
				$( ".row-list" ).sortable({
					helper: 'clone',
					handle: ".sort-handle",
					placeholder: "ui-state-highlight",
					'ui-floating': 'auto',
					'start': function (event, ui) {
						$(".ui-state-highlight").height(ui.item.height());
					},
					'sort': function (event, ui) {
						ui.helper.css({'top': ui.position.top + 'px'});
					},
					'update': function (event, ui) {
						$.post('/admin/'+controller_url+'/sort-row', $(this).sortable('serialize'), function (data) {
							//
						});
					}
				});
			},
			moduleSortable: function() {
				var controller_url = ($("body").hasClass("dlp"))?"dlp-cms":"cms";

				//sort modules
				$(".module-list").sortable({
					helper: 'clone',
					connectWith: '.module-list-connected',
					//handle: ".sort-handle",
					placeholder: "ui-state-highlight",
					'ui-floating': 'auto',
					'start': function (event, ui) {
						$(".ui-state-highlight").height(ui.item.height());
					},
					'sort': function (event, ui) {
						ui.helper.css({'top': ui.position.top + 'px'});
					},
					'update': function (event, ui) {
						$.post('/admin/'+controller_url+'/sort-module/column_id/'+$(this).data('column_id'), $(this).sortable('serialize'), function (data) {
							//
						});
					}
				});
			},
			init: function() {
				//sort sections
				Cny.page_editor.sectionSortable();
				//sort rows
				Cny.page_editor.rowSortable();
				//sort modules
				Cny.page_editor.moduleSortable();

				var controller_url = ($("body").hasClass("dlp"))?"dlp-cms":"cms";

				//initialize section min/max show/hide state
				$("div[data-section] .maximized-section-hidden, div[data-section] .section-label").hide();
				var sections_collapsed = 0;
				$("div[data-section]").each(function(){
					var id = $(this).data('section');

					if (Cookies.get('section-'+id) == 'minimize') {
						$(this).find(".minimized-section-hidden, .cms-rows").hide();
						$(this).find(".maximized-section-hidden, .section-label").show();

						sections_collapsed++;
					}
				});

				if (sections_collapsed == $("div[data-section]").length) {
					$("#toggle_sections").text("Maximize Sections").data("state","maximize");
				}
				$("#toggle_sections").on("click",function() {
					if ($(this).data("state") == "collapse") {
						$("div[data-section]").each(function(){
							$(this).find(".minimize-section-btn").trigger("click");
						});
						$(this).text("Maximize Sections").data("state","maximize");
					}else {
						$("div[data-section]").each(function(){
							$(this).find(".maximize-section-btn").trigger("click");
						});
						$(this).text("Collapse Sections").data("state","collapse");
					}

					return false;
				});

				//fix the sidebar height issue
				var sec_height = $(".section-list")[0].scrollHeight,
					sidebar_height = $(".sidebar--cms")[0].scrollHeight;
				$(".section--cms").css('min-height',(Math.max(sec_height, sidebar_height) + 80) +'px');

				//delete section
				$(".section").on("click",".del-section",function(){
					if (confirm("Are you sure you want to delete this entry? It cannot be undone!")) {
						var id = $(this).data('id');
						$.get('/admin/'+controller_url+'/delete-section', {page_id: $("#page_id").val(), id: id}, function (data) {
							$(".panel[data-section='" + id + "']").remove();
						});
					}
					return false;
				});

				//add section
				$(".section").on("click",".add-section",function(){
					var el = $(this),
						id = el.parent().parent().data('section');
					$.get( '/admin/'+controller_url+'/add-section', {page_id:$("#page_id").val(),prev_id:id,type:$(this).data('type')}, function( data ) {
						$(".panel[data-section='"+id+"']").after(data);
					});

					return false;
				});

				//clone section
				$(".section").on("click",".clone-section",function(){
					var el = $(this),
						id = el.parent().parent().parent().parent().parent().data('section');
					$.get( '/admin/'+controller_url+'/clone-section', {clone_id:id}, function( data ) {
						$(".panel[data-section='"+id+"']").after(data);
					});

					return false;
				})
				//minimize section
				.on("click",".minimize-section-btn",function(){
					var el = $(this),
						id = el.parent().parent().parent().parent().parent().data('section');

					$("div[data-section='" + id + "'] .minimized-section-hidden, div[data-section='" + id + "'] .cms-rows").hide();
					$("div[data-section='" + id + "'] .maximized-section-hidden, div[data-section='" + id + "'] .section-label").show();

					Cookies.set('section-'+id, 'minimize', { path: '' });

					return false;
				})
				//maximize section
				.on("click",".maximize-section-btn",function(){
					var el = $(this),
						id = el.parent().parent().parent().parent().parent().data('section');

					$("div[data-section='" + id + "'] .minimized-section-hidden, div[data-section='" + id + "'] .cms-rows").show();
					$("div[data-section='" + id + "'] .maximized-section-hidden, div[data-section='" + id + "'] .section-label").hide();

					//Cookies.set('section-'+id, 'maximize', { path: '' });
					//since maximize is the default we could just delete the cookie
					Cookies.remove('section-'+id, { path: '' });

					return false;
				});

				//save section
				$("body").on("submit",".section-form",function(){
					//check for media query in custom CSS
					if ($("textarea[name='settings[custom_css]']").val() && $("textarea[name='settings[custom_css]']").val().toLowerCase().indexOf('@media') >= 0) {
						alert("Media Queries not supported in custom CSS");
					}else {
						//save module form
						$.post('/admin/'+controller_url+'/save-section',
							$(".section-form").serialize(),
							function (data) {
								function toTitleCase(str) {
									return str.replace(/\w\S*/g, function (txt) {
										return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
									});
								}

								var settings = JSON.parse(data.json_settings);
								if (settings.label) {
									var title = settings.label;
								} else {
									var title = toTitleCase(data.type) + " Section";
								}
								$("#section-title-" + data.id).text(title);

								$("div[data-section='" + data.id + "'] .desktop_status, div[data-section='" + data.id + "'] .tablet_status, div[data-section='" + data.id + "'] .mobile_status").removeClass('disabled');
								if (data.desktop_status == 'Disabled') {
									$("div[data-section='" + data.id + "'] .desktop_status").addClass('disabled');
								}
								if (data.tablet_status == 'Disabled') {
									$("div[data-section='" + data.id + "'] .tablet_status").addClass('disabled');
								}
								if (data.mobile_status == 'Disabled') {
									$("div[data-section='" + data.id + "'] .mobile_status").addClass('disabled');
								}

								$('#modal').modal('hide');
							}, 'json');
					}

					return false;
				});

				//save section
				$("body").on("submit",".row-form",function(){
					//check for media query in custom CSS
					if ($("textarea[name='settings[custom_css]']").val() && $("textarea[name='settings[custom_css]']").val().toLowerCase().indexOf('@media') >= 0) {
						alert("Media Queries not supported in custom CSS");
					}else {
						//save module form
						$.post('/admin/'+controller_url+'/save-row',
							$(".row-form").serialize(),
							function (data) {
								$("div[data-section_row_id='" + data.id + "'] .desktop_status_row, div[data-section_row_id='" + data.id + "'] .tablet_status_row, div[data-section_row_id='" + data.id + "'] .mobile_status_row").removeClass('disabled');
								if (data.desktop_status === 'Disabled') {
									$("div[data-section_row_id='" + data.id + "'] .desktop_status_row").addClass('disabled');
								}
								if (data.tablet_status === 'Disabled') {
									$("div[data-section_row_id='" + data.id + "'] .tablet_status_row").addClass('disabled');
								}
								if (data.mobile_status === 'Disabled') {
									$("div[data-section_row_id='" + data.id + "'] .mobile_status_row").addClass('disabled');
								}


								$('#modal').modal('hide');
							}, 'json');
					}

					return false;
				});

				//save page title/code
				$("button.page-editor-form").on("click",function(){
					//save
					$.post( '/admin/'+controller_url+'/page-save-partial/page_id/'+$("#page_id").val(),
						$( ".page-editor-form" ).serialize(),
						function(data) {
							$(".current-page-title").text(data.title);
							$(".current-page-code").text(data.code);

							$(".page-editor-default").show();
							$(".page-editor-form").hide();

							$("#preview-btn").attr("href",data.preview_url)
						}, 'json');

					return false;
				});
				$("button.page-editor-default").on("click",function(){
					$(".page-editor-default").hide();
					$(".page-editor-form").show();
				});
				$(".page-title").on("keyup",function(){
					$.get( '/admin/'+controller_url+'/slug-title', {page_id:$("#page_id").val(),title:$(this).val()}, function( data ) {
						$(".page-code").val(data);
					});
				});

				//save page SEO info
				$("body").on("submit",".page-seo-form",function(){
					//save
					$.post( '/admin/'+controller_url+'/page-save-partial/page_id/'+$("#page_id").val(),
						$( ".page-seo-form" ).serialize(),
						function(data) {
							$("#page-meta_title").text(data.meta_title);
							$("#page-meta_keywords").text(data.meta_keywords);
							$("#page-meta_description").text(data.meta_description);
							$("#page-tracking_code").html(data.tracking_code);
							$("#page-meta_canonical_url").html(data.meta_canonical_url);

							$('#modal').modal('hide');
						}, 'json');

					return false;
				});

				//save page options
				$("body").on("change","#page-options-form .ajax-option",function(){
					//save
					$.post( '/admin/'+controller_url+'/page-save-partial/page_id/'+$("#page_id").val(),
						$( "#page-options-form" ).serialize(),
						function(data) {

						}, 'json');

					return false;
				});

				//save page advanced settings
				$("body").on("submit",".page-settings-form",function(){
					//check for media query in custom CSS
					if ($("textarea[name='settings[custom_css]']").val() && $("textarea[name='settings[custom_css]']").val().toLowerCase().indexOf('@media') >= 0) {
						alert("Media Queries not supported in custom CSS");
					}else {
						//save
						$.post('/admin/'+controller_url+'/page-settings-save/page_id/' + $("#page_id").val(),
							$(".page-settings-form").serialize(),
							function (data) {
								$('#modal').modal('hide');
							}, 'json');
					}

					return false;
				});

				//save page custom variables
				$("body").on("submit",".page-custom_vars-form",function(){
					//save
					$.post( '/admin/'+controller_url+'/page-custom-vars-save/page_id/'+$("#page_id").val(),
						$( ".page-custom_vars-form" ).serialize(),
						function(data) {
							$('#modal').modal('hide');
						}, 'json');

					return false;
				});

				//choose columns
				$("body").on("click",".column_selector", function(){
					var row_id = $("#row_id").val();
					var col_type = $(this).find(".row_selection").data("col");

					$.get( '/admin/'+controller_url+'/add-columns', {row_id:row_id,col_type:col_type}, function(data) {
						$("#section_row-"+row_id).replaceWith(data);

						$('#modal').modal('hide');
					});
				});

				//delete row
				$(".section").on("click",".del-row",function(){
					if (confirm("Are you sure you want to delete this entry? It cannot be undone!")) {
						var id = $(this).data('id');
						$.get('/admin/'+controller_url+'/delete-row', {id: id}, function (data) {
							$(".section_row[data-section_row_id='" + id + "']").remove();
						});
					}
					return false;
				});

				//add new row
				$(".section").on("click",".add-row",function(){
					var el = $(this),
						last_row = $(el).parent().parent().parent().find('.row-list .section_row:last-child');
					$.get( '/admin/'+controller_url+'/add-row', {section_id:el.data('section_id'),prev_id:last_row.data('section_row_id')}, function( data ) {
						if (last_row.length > 0) {
							last_row.after(data);
						}else {
							$(el).parent().parent().parent().find('.row-list').html(data);
						}
					});

					return false;
				});

				//clone row
				$("body").on("click",".clone-row",function(){
					var el = $(this),
						section = el.parent().parent().parent().parent().parent().parent(),
						id = section.data('section_row_id'),
						last_row = $(section).parent().parent().find('.row-list .section_row:last-child');
					$.get( '/admin/'+controller_url+'/clone-row', {clone_id:id}, function( data ) {
						if (last_row.length > 0) {
							last_row.after(data);
						}else {
							$(section).parent().parent().find('.row-list').html(data);
						}
					});

					return false;
				});

				//save CMS module
				$("body").on("submit",".cms-module-modal .module-form",function(){
					//check for media query in custom CSS
					if ($("textarea[name='settings[custom_css]']").val() && $("textarea[name='settings[custom_css]']").val().toLowerCase().indexOf('@media') >= 0) {
						alert("Media Queries not supported in custom CSS");
					}else {
						//save module form
						$.post('/admin/'+controller_url+'/save-module',
							$(".module-form").serialize(),
							function (data) {
								//$("#module_label-" + data.id).html(data.label);
								$('#modal').modal('hide');

								$.getJSON('/admin/'+controller_url+'/update-row', {column_id: data.column_id}, function (data) {
									$("#section_row-" + data.row_id).replaceWith(data.content);

									//sort sections
									Cny.page_editor.sectionSortable();
									//sort rows
									Cny.page_editor.rowSortable();
									//sort modules
									Cny.page_editor.moduleSortable();
								});
							}, 'json');
					}

					return false;
				});

				//save Library module
				$("body").on("submit",".library-module-modal .module-form",function(){
					//save module form
					$.post( '/admin/library/save',
						$( ".module-form" ).serialize(),
						function(data) {
							$('#modal').modal('hide');
						}, 'json');

					return false;
				});

				//refresh row (to show change to module)
				$("body").on("click",".row-refresh", function(){
					var column_id = $(this).data('column_id');

					$.getJSON( '/admin/'+controller_url+'/update-row', {column_id:column_id}, function(data) {
						$("#section_row-"+data.row_id).replaceWith(data.content);

						//sort sections
						Cny.page_editor.sectionSortable();
						//sort rows
						Cny.page_editor.rowSortable();
						//sort modules
						Cny.page_editor.moduleSortable();
					});
				});

				//delete module
				$(".section").on("click",".del-module", function(){
					if (confirm("Are you sure you want to delete this entry? It cannot be undone!")) {
						var module_id = $(this).data('module_id');

						$.getJSON('/admin/'+controller_url+'/delete-module', {module_id: module_id}, function (data) {
							$("#section_row-" + data.row_id).replaceWith(data.content);

							//sort sections
							Cny.page_editor.sectionSortable();
							//sort rows
							Cny.page_editor.rowSortable();
							//sort modules
							Cny.page_editor.moduleSortable();
						});
					}
				})

				//clone module
				.on("click",".clone-module",function(){
					var el = $(this),
						id = el.parent().parent().parent().parent().data('module');
					$.getJSON( '/admin/'+controller_url+'/clone-module', {clone_id:id}, function( data ) {
						$("#section_row-"+data.row_id).replaceWith(data.content);

						//sort sections
						Cny.page_editor.sectionSortable();
						//sort rows
						Cny.page_editor.rowSortable();
						//sort modules
						Cny.page_editor.moduleSortable();
					});

					return false;
				});

				//page nav settings
				$("#main_nav_status").on("click",function(){
					if ($(this).prop('checked')) {
						$("#main_nav_parent_id").prop('disabled',false).selectpicker('refresh');
					}else {
						$("#main_nav_parent_id").prop('disabled',true).selectpicker('refresh');
					}
				});

				//ajax save for nav
				$(".ajax-nav-save").on("change",function(){
					var page_id = $("#page_id").val();
					var url = "/admin/'+controller_url+'/page-save-nav";

					$.post(url, {page_id:page_id,main_nav_status:($('#main_nav_status').prop('checked'))?'Enabled':'Disabled',main_nav_parent_id:$("#main_nav_parent_id").val()}, function(){

					});
				});

				//save page password
				$('input[name="data[password_protect]"]').on("change", function (){
					if ($('input[name="data[password_protect]"]:checked').val() == 'Enabled') {
						$(".password_div").show();
					}else {
						$(".password_div").hide();
					}
				});
				$(".password_btn").on("click",function(){
					$.post( '/admin/'+controller_url+'/page-save-password',
						{page_id:$("#page_id").val(),password:$("input[name='data[password]']").val()},
						function(data) {
							$("input[name='data[password]']").val('').attr("placeholder","************");
						});
				});
			}
		},
		// Blog Article Editor
		'blog_editor': {
			init: function() {
				//save page title/code
				$("button.blog-editor-form").on("click",function(){
					//save
					$.post( '/admin/blog/save-partial/page_id/'+$("#page_id").val(),
						$( ".blog-editor-form" ).serialize(),
						function(data) {
							$(".current-page-title").text(data.title);
							$(".current-page-code").text(data.code);

							$(".blog-editor-default").show();
							$(".blog-editor-form").hide();
						}, 'json');

					return false;
				});
				$("button.blog-editor-default").on("click",function(){
					$(".blog-editor-default").hide();
					$(".blog-editor-form").show();
				});
				$(".page-title").on("keyup",function(){
					$.get( '/admin/blog/slug-title', {page_id:$("#page_id").val(),title:$(this).val()}, function( data ) {
						$(".page-code").val(data);
					});
				});

				//save blog page SEO info
				$("body").on("submit",".blog-seo-form",function(){
					//save
					$.post( '/admin/blog/save-partial/page_id/'+$("#page_id").val(),
						$( ".blog-seo-form" ).serialize(),
						function(data) {
							$("#page-meta_keywords").text(data.meta_keywords);
							$("#page-meta_description").text(data.meta_description);

							$('#modal').modal('hide');
						}, 'json');

					return false;
				});

				//save blog page tags
				$("body").on("submit",".blog-tag-form",function(){
					//save
					$.post( '/admin/blog/save-tags/page_id/'+$("#page_id").val(),
						$( ".blog-tag-form" ).serialize(),
						function(data) {
							$('#modal').modal('hide');
						}, 'json');

					return false;
				});

				//save blog page images
				$("body").on("submit",".blog-image-form",function(){
					//save
					$.post( '/admin/blog/save-partial/page_id/'+$("#page_id").val(),
						$( ".blog-image-form" ).serialize(),
						function(data) {
							$('#modal').modal('hide');
						}, 'json');

					return false;
				});

				//save page options
				$("body").on("change","#blog-options-form .ajax-option",function(){
					//save
					$.post( '/admin/blog/save-partial/page_id/'+$("#page_id").val(),
						$( "#blog-options-form" ).serialize(),
						function(data) {

						}, 'json');

					return false;
				});
			}
		},
		'page_list_view': {
			init: function() {
				window.cny.triggerPageIntroJS();
			}
		},
		'blog_list_view': {
			init: function() {
				window.cny.triggerBlogIntroJS();
			}
		},
		'site_settings': {
			init: function() {
				$(".chkbox-toggle").on("change", function(){
					$("#"+$(this).data('rel')+" input[type=checkbox]").prop("checked", $(this).prop("checked"));
				});
			}
		},
		'password_reset': {
			init: function() {
				$('#recoverform .show-password').click(function(){
					var type = $(this).parent().siblings('input').attr('type');
					if( type === 'password' ){
						$(this).html('<i class="fas fa-eye-slash"></i> Hide Password');
						$(this).parent().siblings('input').attr("type", "text");
					}else{
						$(this).html('<i class="fas fa-eye"></i> Show Password');
						$(this).parent().siblings('input').attr("type", "password");
					}

					return false;
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Cny;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
