(function (cny, $) {
	cny.functionName = function () {
		var $container = $('');
		if (!$container.length) {
			return;
		}
	};

	cny.wysiwyg = function () {
		if (typeof tinyMCE === 'undefined') {
			console.log('tinyMCE not loaded');
			return;
		}

		tinyMCE.remove();

		tinymce.init({
			selector: ".tinymce",
			theme: "silver",
			height: (typeof window.wysiwyg_settings != 'undefined' && typeof window.wysiwyg_settings.height != 'undefined' && window.wysiwyg_settings.height)?window.wysiwyg_settings.height:500,
			plugins: [
				'advlist autolink lists link image charmap hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen',
				'insertdatetime media nonbreaking table directionality',
				'emoticons template paste textpattern',
				'codesample imagetools faicons'//noneditable
			],
			relative_urls: false,

			external_plugins: {"faicons" : "/plugins/tinymce/plugins/faicons/plugin.js"},
			filemanager_title:"Responsive Filemanager",
			external_filemanager_path:"/filemanager/",

			content_css: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css, https://netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css',
			//noneditable_noneditable_class: 'fa',
			extended_valid_elements: 'span[*],a[*]',

			image_advtab: true,
			fontsize_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 48px 50px 60px 64px 72px 76px 80px 86px 90px 100px 120px 140px 200px 240px 0.5rem 0.75rem 0.9rem 1rem 1.1rem 1.25rem 1.4rem 1.5rem 1.75rem 1.9rem 2rem 2.2rem 2.4rem 2.5rem 2.75rem 2.8rem 2.9rem 3rem 3.5rem 4rem 4.5rem 5rem 5.5rem 6rem 6.5rem 7rem 7.5rem 8rem 8.5rem 9rem 9.5rem 10rem",
			lineheight_formats: "8px 9px 10px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 38px 40px 42px 48px 50px 60px 64px 72px 76px 80px 86px 90px 100px 120px 140px 200px 240px 0.5rem 0.75rem 0.9rem 1rem 1.1rem 1.25rem 1.4rem 1.5rem 1.75rem 1.9rem 2rem 2.2rem 2.4rem 2.5rem 2.75rem 2.8rem 2.9rem 3rem 3.5rem 4rem 4.5rem 5rem 5.5rem 6rem 6.5rem 7rem 7.5rem 8rem 8.5rem 9rem 9.5rem 10rem",
			//font_formats: 'Default=;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',

			toolbar1: 'undo redo | insert | styleselect fontselect fontsizeselect lineheight | bullist numlist | outdent indent | ',
			toolbar2: 'bold italic | alignleft aligncenter alignright alignjustify | link | image media | forecolor backcolor | hr | emoticons faicons | removeformat | editorbg | code | fullscreen',

			setup: function (editor) {
				editor.on('init', function (e) {
					//editor.execCommand("fontName", false, "Default");
					$("#" + editor.targetElm.id).removeClass("tinymce");
				});
				editor.ui.registry.addButton('editorbg', {
					icon: 'contrast',
					tooltip: 'Toggle editor background color',
					onAction: function (buttonApi) {
						if ($(tinymce.activeEditor.getBody()).css("background-color") == '#000000' || $(tinymce.activeEditor.getBody()).css("background-color") == 'rgb(0, 0, 0)') {
							$(tinymce.activeEditor.getBody()).css("background-color",'#FFFFFF');
						}else {
							$(tinymce.activeEditor.getBody()).css("background-color",'#000000');
						}
					}
				});
				editor.on('FullscreenStateChanged', function (e) {
					if (e.state === true) {
						$("#modal .modal__dialog").removeClass("modal--lg").addClass("modal--full");
					}else {
						$("#modal .modal__dialog").removeClass("modal--full").addClass("modal--lg");
					}
				});
			}
		});

		// Prevent Bootstrap dialog from blocking focusin
		$(document).on('focusin', function(e) {
			if ($(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
				e.stopImmediatePropagation();
			}
		});

		$('#modal').on('hidden.bs.modal', function (e) {
			tinyMCE.remove();
		});
	};

	cny.wysiwygDLP = function () {
		if (typeof tinyMCE === 'undefined') {
			console.log('tinyMCE not loaded');
			return;
		}

		tinyMCE.remove();

		tinymce.init({
			selector: ".tinymce",
			height: (typeof window.wysiwyg_settings != 'undefined' && typeof window.wysiwyg_settings.height != 'undefined' && window.wysiwyg_settings.height)?window.wysiwyg_settings.height:500,
			plugins: [
				"autolink lists link image lists media table",
				"searchreplace visualblocks",
				"paste charmap code" //spellchecker (doesn't work)
			],
			toolbar: "bold italic underline | fontsizeselect | bullist numlist | link image  | undo redo | alignleft aligncenter alignright alignjustify | editorbg | code",
			relative_urls : false,
			menubar: true,

			//external_plugins: {"responsivefilemanager" : "/plugins/tinymce/plugins/responsivefilemanager/plugin.min.js", "filemanager" : "/filemanager/plugin.min.js"},
			//filemanager_title:"Responsive Filemanager",
			//external_filemanager_path:"/filemanager/",
			extended_valid_elements: 'span[*],a[*]',

			setup: function (editor) {
				editor.on('init', function (e) {
					//editor.execCommand("fontName", false, "Default");
					$("#" + editor.targetElm.id).removeClass("tinymce");
				});
				editor.ui.registry.addButton('editorbg', {
					icon: 'contrast',
					tooltip: 'Toggle editor background color',
					onAction: function (buttonApi) {
						if ($(tinymce.activeEditor.getBody()).css("background-color") == '#000000' || $(tinymce.activeEditor.getBody()).css("background-color") == 'rgb(0, 0, 0)') {
							$(tinymce.activeEditor.getBody()).css("background-color",'#FFFFFF');
						}else {
							$(tinymce.activeEditor.getBody()).css("background-color",'#000000');
						}
					}
				});
				editor.on('FullscreenStateChanged', function (e) {
					if (e.state === true) {
						$("#modal .modal__dialog").removeClass("modal--lg").addClass("modal--full");
					}else {
						$("#modal .modal__dialog").removeClass("modal--full").addClass("modal--lg");
					}
				});
			},

			style_formats: [
				{ title: 'Main Title', block: 'h1', styles: { color: '#388842'} },
				{ title: 'Secondary Title', block: 'h2', styles: { color: '#9ccb3b'} },
				/*
				{ title: 'Custom format', format: 'customformat' },
				{ title: 'Align left', format: 'alignleft' },
				{ title: 'Align center', format: 'aligncenter' },
				{ title: 'Align right', format: 'alignright' },
				{ title: 'Align full', format: 'alignfull' },
				{ title: 'Bold text', inline: 'strong' },
				{ title: 'Red text', inline: 'span', styles: { color: '#ff0000' } },
				{ title: 'Red header', block: 'h1', styles: { color: '#ff0000' } },
				{ title: 'Table row 1', selector: 'tr', classes: 'tablerow1' },
				{ title: 'Image formats' },
				{ title: 'Image Left', selector: 'img', styles: { 'float': 'left', 'margin': '0 10px 0 10px' } },
				{ title: 'Image Right', selector: 'img', styles: { 'float': 'right', 'margin': '0 0 10px 10px' } },
				 */
			],
			color_map: [
				'#388842', 'HLC Dark Green',
				'#9ccb3b', 'HLC Light Green',
				'#65ae45', 'HLC Green',
				'#37a187', 'HLC Turquoise',
				'#c9178d', 'HLC Magento',
				'#f0cc20', 'HLC Yellow',


				'#E03E2D', 'Red',
				'#B96AD9', 'Purple',
				'#3598DB', 'Blue',

				'#E67E23', 'Orange',
				'#BA372A', 'Dark Red',
				'#843FA1', 'Dark Purple',
				'#236FA1', 'Dark Blue',

				'#ECF0F1', 'Light Gray',
				'#CED4D9', 'Medium Gray',
				'#95A5A6', 'Gray',
				'#7E8C8D', 'Dark Gray',

				'#000000', 'Black',
				'#ffffff', 'White'
			]
			//force_p_newlines : false,
			//forced_root_block : false
			//content_css : "/css/admin/main.css"
		});

		// Prevent Bootstrap dialog from blocking focusin
		$(document).on('focusin', function(e) {
			if ($(e.target).closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
				e.stopImmediatePropagation();
			}
		});

		$('#modal').on('hidden.bs.modal', function (e) {
			tinyMCE.remove();
		});
	};

	cny.colorpicker = function () {
		$(".complex-colorpicker").asColorPicker({
			mode: 'complex',
			onOpen: function() {
				const hlcColorMap = ['#388842','#9ccb3b','#65ae45','#37a187','#c9178d','#f0cc20'];
				const paletteList = document.querySelectorAll('.asColorPicker-palettes');

				paletteList.forEach(list => {
					list.innerHTML = '';
					defaultColors(list)
				});

				function defaultColors(list) {
					hlcColorMap.forEach(color => {
						list.innerHTML += `<li data-color="${color}"><span style="background-color:${color}"></span></li>`;
					});
				}

			}
		});
	};

	cny.moduleSubPanels = function () {

		$(".panel-edit").hide();

		$("#add-panel").on("click",function(){
			//var count = $("#hero-list .list-row").length;
			var count = 0;
			if ($("#hero-list .list-row").length > 0) {
				$("#hero-list .list-row").each(function (data) {
					var max_count = parseInt($(this).find('input[data-name="sort_order"]').val());
					if (max_count > count) {
						count = max_count;
					}
				});
				count++;
			}
			$("#panelForm-sort_order").val(count);

			$(".panel-view").hide();
			$(".panel-edit").show();
			$("#save").parent().hide();

			$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				if ($(e.target).attr("href") === '#settings') {
					$("#save").parent().show();
				} else if ($(e.target).attr("href") === '#history') {
					$("#save").parent().show();
					$("button.save-module").hide();
				} else {
					$("#save").parent().hide();
				}
				//e.target // newly activated tab
				//e.relatedTarget // previous active tab
			});

			$(".selectpicker").selectpicker('refresh');

			return false;
		});

		$("#cancel-panel, #save-panel").on("click",function(){

			if ($(this).attr("id") === "save-panel") {
				for(i=0; i<tinymce.editors.length; i++){
					$("#" + tinymce.editors[i].targetElm.id).val(tinymce.editors[i].getContent());
				}

				if ($("#panelForm-id").val()) {
					var count = parseInt($("#panelForm-id").val());
					var label = ($("#panelForm-label").val() != '') ? $("#panelForm-label").val() : "Panel " + (count + 1);
					var panel_image_src = ($("#panelForm .copy.panel-image-src").val() != '') ? $("#panelForm .copy.panel-image-src").val() : '';

					var row = $("#hero-list").find("div[data-id='panel_"+count+"']");
					$("#panelForm .copy").each(function() {
						var name = $(this).data("name");
						var input_type = ($(this).data("type"))?$(this).data("type"):'input';

						if (row.find(input_type+"[name='content[panels][" + count + "][" + name + "]']").length > 0) {
							row.find(input_type+"[name='content[panels][" + count + "][" + name + "]']").val($(this).val());
						}else {
							if (input_type == 'textarea') {
								row.append('<textarea class="hide" name="content[panels][' + count + '][' + name + ']" data-name="' + name + '">' + $(this).val() + '</textarea>');
							}else {
								row.append('<input type="hidden" name="content[panels][' + count + '][' + name + ']" value="' + $(this).val() + '" data-name="' + name + '">');
							}
						}
					});
					row.find(".panel__title").text(label).end()
						.find(".panel__image img").attr('src',panel_image_src).end()
						.find("button").data("rel", count).end();
				}else {
					//var count = $("#hero-list .list-row").length;
					var count = 0;
					if ($("#hero-list .list-row").length > 0) {
						$("#hero-list .list-row").each(function (data) {
							var max_count = parseInt($(this).data('id').replace('panel_', ''));
							if (max_count > count) {
								count = max_count;
							}
						});
						count++;
					}

					var label = ($("#panelForm-label").val() != '') ? $("#panelForm-label").val() : "Panel " + (count + 1);
					var panel_image_src = ($("#panelForm .copy.panel-image-src").val() != '') ? $("#panelForm .copy.panel-image-src").val() : '';

					var new_row = $("#new-panel_row").clone();

					$("#panelForm .copy").each(function() {
						var name = $(this).data("name");
						var input_type = ($(this).data("type"))?$(this).data("type"):'input';

						if (input_type == 'textarea') {
							new_row.append('<textarea class="hide" name="content[panels][' + count + '][' + name + ']" data-name="' + name + '">' + $(this).val() + '</textarea>');
						}else {
							new_row.append('<input type="hidden" name="content[panels][' + count + '][' + name + ']" value="' + $(this).val() + '" data-name="' + name + '">');
						}
					});

					new_row.attr("id", "").attr("data-id", "panel_" + count)
						.find(".panel__title").text(label).end()
						.find(".panel__image img").attr('src',panel_image_src).end()
						.find("button").data("rel", count).end()
						.removeClass("hidden")
						.appendTo("#hero-list .list-div");
				}
			}

			$("#panelForm #panelForm-id").val('');
			$("#panelForm .copy").each(function() {
				if ($(this).prop('nodeName').toLowerCase() == 'img') {
					$(this).attr("src", "");
				}else if ($(this).prop('nodeName').toLowerCase() == 'select') {
					$(this).val($(this).find("option:first").val());
				}else {
					$(this).val("");
				}

				//clear tinyMCE
				for(i=0; i<tinymce.editors.length; i++){
					if (tinymce.editors[i].id.indexOf("panelForm-") >= 0) {
						tinymce.editors[i].setContent("");
						//$("[name='" + tinymce.editors[i].targetElm.name + "']").val("");
					}
				}
			});

			$(".panel-view").show();
			$(".panel-edit").hide();
			$(".panel-edit .conditional").hide();
			$("#save").parent().show();

			$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				if ($(e.target).attr("href") === '#content') {
					$("#save").parent().show();
				}
				//e.target // newly activated tab
				//e.relatedTarget // previous active tab
			});

			return false;
		});

		$("#hero-list").on("click",".panel-edit-btn", function(){
			//reset text weight options
			var options = {
				300: 'Light',
				400: 'Regular',
				500: 'Medium',
				600: 'Semi Bold',
				700: 'Bold',
				800: 'Bolder'};

			$("#hero-list .font-selector").each(function() {
				var weight_input_name = $(this).data("weight");
				$('select[name="'+weight_input_name+'"]').children().remove().end().append($('<option>').text('Default').attr('value', ''));
				$.each(options, function (key, val) {
					$('select[name="' + weight_input_name + '"]').append($('<option>').text(val).attr('value', key));
				});
			});

			var row = $("#hero-list").find("div[data-id='panel_"+$(this).data("rel")+"']");

			$("#panelForm-id").val($(this).data("rel"));

			row.find("input[type='hidden'], textarea.hide").each(function() {
				var name = $(this).data('name');

				/*
				if ((typeof $(this).val() == 'undefined' || $(this).val() == '')) {
					if ($("#panelForm-" + name).prop('nodeName').toLowerCase() == 'select') {
						$("#panelForm-" + name).val($("#panelForm-" + name + " option:first").val());
					}else {
						$("#panelForm-" + name).val($("#panelForm-" + name).attr("value"));
					}
				}else {
					$("#panelForm-" + name).val($(this).val());
				}
				*/

				if ($("#panelForm-" + name).attr("multiple") == 'multiple') {
					$("#panelForm-" + name).val($(this).val().split(","));
				}else {
					$("#panelForm-" + name).val($(this).val());
				}

				if (name.substr(name.lastIndexOf("_")+1) == 'path') {
					$("#panelForm-"+name.substr(0,name.lastIndexOf("_"))).attr("src", $(this).val()).show();
				}

				for(i=0; i<tinymce.editors.length; i++){
					if ($("#" + tinymce.editors[i].targetElm.id).length > 0) {
						tinymce.editors[i].setContent($("#" + tinymce.editors[i].targetElm.id).val());
					}
				}
			});

			$(".panel-view").hide();
			$(".panel-edit").show();
			$("#save").parent().hide();

			//set colors properly in colorpicker elements
			$(".complex-colorpicker").each(function(){
				$(this).asColorPicker('val', $(this).val());
			});

			$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				if ($(e.target).attr("href") === '#settings') {
					$("#save").parent().show();
				} else if ($(e.target).attr("href") === '#history') {
					$("#save").parent().show();
					$("button.save-module").hide();
				} else {
					$("#save").parent().hide();
				}
				//e.target // newly activated tab
				//e.relatedTarget // previous active tab
			});

			//show conditional trigger field sections
			$(".conditional").hide();
			$(".conditional-trigger").each(function(){
				$("#"+$(this).val()).show();
			}).on("change",function(){
				$(".conditional").hide();
				$("#"+$(this).val()).show();
			});

			$(".selectpicker").selectpicker('refresh');

			$(".filemanager-image").each(function(){
				var id = $(this).data("image_src");
				if ($(id).val() == '') {
					$(this).hide();
				}else {
					$(this).attr("src",$(id).val()).show();
				}
			});

			return false;
		});

		$("#hero-list").on("click",".panel-del-btn", function(){
			$("#hero-list").find("div[data-id='panel_"+$(this).data("rel")+"']").remove();
		});

		$(".filemanager-image").each(function(){
			var id = $(this).data("image_src");
			if ($(id).val() == '') {
				$(this).hide();
			}else {
				$(this).attr("src",$(id).val()).show();
			}
		});

		//sort panels
		$( "#hero-list .list-div" ).sortable({
			helper: 'clone',
			//handle: ".sort-handle",
			placeholder: "ui-state-highlight",
			'ui-floating': 'auto',
			'start': function (event, ui) {
				$(".ui-state-highlight").height(ui.item.height());
			},
			'sort': function (event, ui) {
				ui.helper.css({'top': ui.position.top + 'px'});
			},
			'update': function (event, ui) {
				var count = 0;
				$("#hero-list .list-row").each(function() {
					$(this).find("input[data-name='sort_order']").val(count);
					count++;
				});
			}
		});

		//toggle certain sections based on other selections
		if ($("input[name='content[fullscreen]']:checked").val() == 'off') {
			$(".fullscreen-off").show();
		}else {
			$(".fullscreen-off").hide();
		}
		$("input[name='content[fullscreen]']").on("change",function(){
			if ($("input[name='content[fullscreen]']:checked").val() == 'off') {
				$(".fullscreen-off").show();
			}else {
				$(".fullscreen-off").hide();
			}
		});

		$("body").on("click", ".btn-clear_image", function(){
			var img = $(this).data('rel');
			$("#"+img+"_path").val('');
			$("#"+img).attr('src','').hide();
		});

		//clone panel
		$("body").off("click",".clone-panel").on("click",".clone-panel",function(){
			var el = $(this),
				panel = el.parent().parent().parent().parent(),
				count = 0;

			if ($("#hero-list .list-row").length > 0) {
				$("#hero-list .list-row").each(function (data) {
					var max_count = parseInt($(this).data('id').replace('panel_', ''));
					if (max_count > count) {
						count = max_count;
					}
				});
				count++;
			}

			var label = panel.find(".panel__title").text() + " - COPY";  //"Panel " + (count + 1);
			var new_row = panel.clone();

			new_row.find("input[type='hidden'], textarea.hide").each(function(){
				$(this).attr("name",$(this).attr("name").replace(/content\[panels\]\[[0-9+]\]/gi,"content[panels]["+count+"]"));
			});

			new_row.attr("id", "").attr("data-id", "panel_" + count)
				.find(".panel__title").text(label).end()
				.find("input[data-name='label']").val('').end()
				.find("input[data-name='sort_order']").val(count).end()
				.find("button").data("rel", count).end()
				.appendTo("#hero-list .list-div");

			return false;
		});
	};

	cny.moduleTabs = function () {

		$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			if ($(e.target).attr("href") === '#history') {
				$("button.save-module").hide();
			}else {
				$("button.save-module").show();
			}
			//e.target // newly activated tab
			//e.relatedTarget // previous active tab
		});
	};

	cny.moduleHistory = function () {
		var select = $("#restore_time");
		if (select.length > 0) {
			var slider = $("#slider").slider({
				min: 1,
				max: select.find("option").length,
				range: "min",
				value: select[0].selectedIndex + 1,
				slide: function (event, ui) {
					select[0].selectedIndex = ui.value - 1;
					$("#restore_time_display").text(select.find("option:selected").text());
				}
			});

			var controller_url = ($("body").hasClass("dlp"))?"dlp-cms":"cms";

			$(".revert-module").on("click", function () {
				$.post('/admin/'+controller_url+'/revert-module', {history_id: select.val()},
					function (data) {
						$("#module_label-" + data.id).html(data.label);

						$('#modal').modal('hide');
					}, 'json');
			});
		}
	};

	cny.fontWeightUpdater = function () {
		$("body").off("change", ".font-selector").on("change", ".font-selector",function(){
			var font_name = $(this).val();
			var weight_input_name = $(this).data("weight");
			var options = {
				300: 'Light',
				400: 'Regular',
				500: 'Medium',
				600: 'Semi Bold',
				700: 'Bold',
				800: 'Bolder'};
			var current_selected = $('select[name="'+weight_input_name+'"]').val();

			var controller_url = ($("body").hasClass("dlp"))?"dlp-cms":"cms";

			$.getJSON( "/admin/"+controller_url+"/font-weight-ajax", {font_name:font_name}, function( data ) {
				$('select[name="'+weight_input_name+'"]').children().remove().end().append($('<option>').text('Default').attr('value', ''));

				var match_selected = false;
				if (data) {
					$.each(data, function (key, val) {
						if (options[val]) {
							$('select[name="' + weight_input_name + '"]').append($('<option>').text(options[val]).attr('value', val));

							if (val == current_selected) {
								match_selected = true;
							}
						}
					});
				}else {
					$.each(options, function (key, val) {
						$('select[name="' + weight_input_name + '"]').append($('<option>').text(val).attr('value', key));

						if (key == current_selected) {
							match_selected = true;
						}
					});
				}

				if (match_selected === false) {
					current_selected = '';
				}

				$('select[name="'+weight_input_name+'"]').val(current_selected);
			});
		});
	};

	cny.videoPlayer = function () {
		//load MediaElements video player
		$('.mediaplayer').each(function() {
			//if (typeof $(this).attr("id") == 'undefined' || $(this).attr("id").indexOf("mejs_") === -1) {
			if (!$(this).hasClass('mejs__container')) {
				$(this).mediaelementplayer({
					//pluginPath: "/path/to/shims/",
					// When using jQuery's `mediaelementplayer`, an `instance` argument is available in the `success` callback
					success: function (mediaElement, originalNode, instance) {
						// do things
					}
				});
			}
		});
	}

	cny.previewButton = function () {

		let formID, dataType, dataTypeClassOptions,  styles, stylesHover, previewBtn, refreshBtn, style, ref;

		formID = document.querySelector('.module-form').getAttribute('id');
		dataType = document.querySelectorAll('#'+formID+' .form-control[data-type]');
		dataTypeClassOptions = document.querySelector('.form-control[data-type="class"]');

		previewBtn = document.querySelector('.btnPreview');
		refreshBtn = document.querySelector('.btnRefresh');

		styles = [];
		stylesHover = [];


		function previewData(el) {

			if (!el ) {
				return;
			}

			el.forEach(function(item, index) {
				switch (item.nodeName) {
					case 'INPUT':
						switch (item.dataset.type) {
							case 'label':
								if (item.value !== '') {
									previewBtn.textContent = item.value;
								}
								break;
							case 'hover':
								stylesHover.push(item.dataset.style + ': ' + item.value);
								break;
							case 'width':
								styles.push(item.dataset.style + ': ' + item.value.replace(/[^0-9.]/g,'') + 'px');
								break;
							default:
								styles.push(item.dataset.style + ': ' + item.value);
						}
						break;
					case 'SELECT':
						switch (item.dataset.type) {
							case 'class':
								let currentValue = item.options[item.selectedIndex].value;
								let values = Array.from(dataTypeClassOptions.options).map(e => e.value);

								previewBtn.classList.remove(...values);
								previewBtn.classList.add(currentValue);
								break;
							case 'padding':
								if (item.name === 'content[button_padding_tb]' && item.value > 0) {
									styles.push('padding-top: ' + item.value);
									styles.push('padding-bottom: ' + item.value);
								}
								if (item.name === 'content[button_padding_lr]' && item.value > 0) {
									styles.push('padding-left: ' + item.value);
									styles.push('padding-right: ' + item.value);
								}
								break;
							case 'font':
								styles.push(item.dataset.style + ':' + '"' + item.value.replace(/_/g, ' ') + '"');
								break;
							default:
								if (item.value) {
									styles.push(item.dataset.style + ': ' + item.value);
								}

						}
				}
			});

			style = document.createElement('style');
			style.setAttribute('data-type','style');
			style.innerHTML =
				'#'+formID+' .btnPreview {' +
				styles.join('; ') +
				'}'+
				'#'+formID+' .btnPreview:hover {' +
				stylesHover.join('; ') +
				'}';
			ref = document.querySelector('script');
			ref.parentNode.insertBefore(style, ref);

		}

		previewData(dataType);

		refreshBtn.addEventListener('click', e => {
			e.preventDefault();
			styles = [];
			stylesHover = [];
			style.remove();
			previewData(dataType);
		});


	};

}(window.cny = window.cny || {}, jQuery));
