(function (cny, $) {
	cny.triggerIntroJS = function () {
		var introTour = introJs();
		introTour.setOptions({
			skipLabel: "Dismiss",
			doneLabel: "Dimiss",
			steps: [
				{
					intro: "<img src=\"/admin_skin/assets/images/cny-logic-logo.png\" alt=\"home\" class=\"img-responsive center-block\"> Welcome to LOGIC Business Cloud. Upon login, you will be directed to this dashboard with quick links to configure your website.  Once Google Analytics has been integrated within LOGIC, these set up links will be replaced by an analytics dashboard display.",
					tooltipClass: "introjs-fullwidth",
					scrollToElement: false,
					scrollTo: "tooltip"

				},
				{
					element: '#side-menu',
					intro: "The left side navigation bar contains links to all of LOGIC’s main functionality.  From here you can manage pages, navigation, blog posts, and all website configurations. Click on any tab to display additional options for each section.",
					position: 'right',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step1',
					intro: "Use the website tab to manage pages, navigation, and blocks. Click on the tab to see additional features for managing your website content and begin adding pages.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step2',
					intro: "Manage your website pages by clicking on the \"Pages\" tab. From this section you will be able to add, edit, delete and duplicate pages on your website.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step3',
					intro: 'Manage your website navigation, or "top nav" structure from the Navigation tab. From this section, you can arrange the order of your top nav links, add links to external websites, and manage sub-nav or drop menus.',
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step4',
					intro: "Blocks are shared elements that appear throughout your website such as pop-ups or page headers. Use this section to manage your website footer, mobile footer, custom headers, modals (pop-ups), and other custom shared elements.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step5',
					intro: "LOGIC allows users to save their favorite customized modules to a library so that they can be shared and duplicated across multiple pages. This section allows you to view and delete modules stored in your Library.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step6',
					intro: "Use templates to create layouts that will be used repeatedly throughout sections of the website.  Templates use blocks to populate the shared sections of the layouts while leaving the center content area empty for editing at the page level.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},

				{
					element: '.step7',
					intro: "LOGIC includes a suite of marketing tools that can be accessed from the sub-links.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step8',
					intro: "LOGIC includes a complete blog section where you can add, edit, and delete blog posts that will appear by default at yoursitename.com/blog.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step9',
					intro: "The Form Submissions tab contains a database of all user entries made from forms created on the website using the form module.  Use this database to collect contact forms, newsletter subscriptions, or any other custom forms added to the website.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth",
					scrollToElement: true
				},
				{
					element: '.step10',
					intro: "Add an announcement bar to the top of the website and promotional pop-ups to the home page or all pages.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step11',
					intro: "The Email tab allows you to configure the design and settings of outbound emails that are generated from orders, registrations, password resets, or any other functions that generate an email from the website.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step12',
					intro: "LOGIC has a full CRM and suite of marketing automations for generating and tracking email campaigns with personalized responses. Marketing automation is part of a subscription service that can be activated by your account manager. ",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step13',
					intro: "Use the SEO tab to add Google Analytics and the Google View ID to enable your website’s dashboard analytics.  You can also add default meta data and custom tracking code from this tab.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step14',
					intro: "Use the Media Manager to upload and manage image files (png, jpeg, and gif), PDFs, video (MP4), and any other web accessible document formats. Create folders to organize assets and upload media using the \"drag and drop\" upload tool. Right click the icon of a file to get details on filename and url.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step15',
					intro: "Use the Settings tab to manage all of LOGIC's global features including admin users, website logos, identity, fonts, styles, and more.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step16',
					intro: "Add, edit, and delete website administrators. Reset passwords, disable access, and set permission levels.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step17',
					intro: "This is the control panel for the appearance and customization of all website elements. The Website settings section is divided into 5 tabs: Site Identity - set your logo, select header format , and insert analytics and other header tags, Social Media Settings - Enable social site icons and add your urls to Facebook, Twitter and other social sites, Site Styling - Assign font and text styles for pages, navigation, heading tags (H1, H2, etc.), and set background colors , Blog - Set your blog's byline, and about text to appear on all blog posts, Plugins - This section is used to enable additional functionality for event management, ecommerce, and other add-ons.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step18',
					intro: "Manage all fonts available in LOGIC from the Font Management tab. View a full list of available web fonts pre-installed in LOGIC and upload your favorite web fonts from Google, Adobe, or other libraries of WTF/OTF or WOFF files or direct font URLs.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step19',
					intro: "If you are having trouble viewing recent updates, this could be due to LOGIC's page caching. By flushing the cache, LOGIC will force all updates to be displayed from the server. Then refresh your browser to see the updates.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.step20',
					intro: "Use the profile tab to manage your account, password, access support, and logout of the LOGIC admin.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					intro: "<img src=\"/admin_skin/assets/images/cny-logic-logo.png\" alt=\"home\" class=\"img-responsive center-block\"> This concludes your introduction to LOGIC.  Additional tutorials are available on each page from support icon in the right column.  What would you like to do next?  <a href='/admin/cms/page-list?multipage=true'>Explore Pages</a>, <a href='/admin/blog?multipage=true'>Learn About the Blog</a>, <a href='/admin/settings/site'>Configure Settings</a>, <a href='https://8c75b3ed63f6faaae7aa-12010748862db1aaadaf12efaeb162f7.ssl.cf2.rackcdn.com/LOGIC_User_Guide.pdf' target='_blank'>Download User Guide</a>, <a class='js-exit-tour' onclick='introJs().exit()' style='cursor: pointer'>Exit the Tour</a>.",
					tooltipClass: "introjs-fullwidth",
					scrollToElement: false,
					scrollTo: "tooltip"

				},
			]
		});
		$('.js-trigger-intro').on('click',function () {
			//TODO need to write a better script
			// var parentListItem = $('.steps');
			//
			// parentListItem.each(function() {
			// 	var $this = $(this);
			// 	if ( !$this.hasClass('active') && !$this.parent().hasClass('active') ) {
			// 		$('.steps').parent().addClass('active').find('.collapse').addClass('in');
			// 	}
			// });

			$('.steps').next().addClass('in');


			introTour.onchange(function() {
				if(this._currentStep === 15) {
					$('.profile-pic').dropdown('toggle');
				}
			}).start();

		});
		$('.js-exit-tour').on('click',function (e) {
			e.preventDefault();
			introTour.exit();
		});
	};
	cny.triggerPageIntroJS = function () {
		var introPageTour = introJs();
		introPageTour.setOptions({
			skipLabel: "Dismiss",
			doneLabel: "Dimiss",
			steps: [
				{
					intro: "<img src=\"/admin_skin/assets/images/cny-logic-logo.png\" alt=\"home\" class=\"img-responsive center-block\"> Welcome to the Page Manager. This tour will show you the basic features for managing the pages on your website. The Page Manager lists all website pages with options to add, edit, and delete pages as well as preview pages and duplicate.  If this is a new website, you may not have any pages listed until your first page is created.",
					tooltipClass: "introjs-fullwidth",
					scrollToElement: false,
					scrollTo: "tooltip"

				},
				{
					element: '.js-intro-search',
					intro: "Quickly locate specific pages by searching and filtering.  This becomes especially helpful as your website grows to a large number of pages.  Check the \"Export\" option and then search to export a list of all pages to a CSV file.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-add-new',
					intro: "Click on the \"Add New Page\" button to create a new page. You will be redirected to a form where you can fill out basic information about the page including Page Title, URL Code, Meta Data for SEO, Campaign Tracking Code, and set the status to Enabled or Disabled. By default, all new pages are Enabled because they are not automatically added to navigation. Note: Only \"Page Title\" is required to create the new page. All other fields are optional.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-page-title',
					intro: "Pages are identified by the Page Title and sorted in alphabetical order according to the title name. Click on the page title to edit the page.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-creation-date',
					intro: 'The time and date the page was created is displayed here to help you manage pages and sort by the date they were entered.',
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-last-modified',
					intro: "The time and date the page was last edited.  This is helpful in tracking recent changes.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-status',
					intro: "Determine if a page is enabled or disabled. Disabled pages cannot be viewed even if they are linked via the navigation and are typically a draft of a page being created or an alternate version of a page that is only enabled during certain periods.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-delete',
					intro: "Select this icon to delete a page.  A warning prompt will be displayed as this cannot be undone.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-duplicate',
					intro: "Click on this icon to make a duplicate copy of a page. This is very helpful for quickly creating a new page that inherits the attributes of another similar page.  Or creating an alternate version that can be activated during specific time periods (ex: Holiday Promotions). All duplicated pages are disabled by default.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-preview',
					intro: "Click on this icon to open a new tab with a preview of the page. Preview will display pages that are both enabled or disabled.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-edit',
					intro: "Click on this icon to go to the page editor where the selected page can be updated.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				}
			]
		});
		$('.js-trigger-intro').on('click',function () {
			//TODO need to write a better script
			introPageTour.start();
		});
		if (RegExp('multipage', 'gi').test(window.location.search)) {
			introPageTour.start();
		}
	};
	cny.triggerBlogIntroJS = function () {
		var introBlogTour = introJs();
		introBlogTour.setOptions({
			skipLabel: "Dismiss",
			doneLabel: "Dismiss",
			steps: [
				{
					intro: "<img src=\"/admin_skin/assets/images/cny-logic-logo.png\" alt=\"home\" class=\"img-responsive center-block\"> Welcome to the LOGIC Blog. This tour will show you the basic features for managing blog posts. The LOGIC Blog allows you to add, edit, delete stories that will display, by default at www.yourdomain.com/blog.  A blog article listing page will automatically be created after your first post. You can also use the Blog Feed module to display a list of recent posts on any page on your website.",
					tooltipClass: "introjs-fullwidth",
					scrollToElement: false,
					scrollTo: "tooltip"

				},
				{
					element: '.js-intro-search',
					intro: "Quickly locate specific blog posts by searching and filtering.  This becomes especially helpful as your blog grows to a large number of posts.  Check the \"Export\" option and then search to export a list of all posts to a CSV file.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-add-new',
					intro: "Click on the \"Add New Post\" button to create a new post. You will be redirected to a form where you can fill out basic information about the post including Title, URL Code, Meta Data for SEO, Short Summary, Content, Post Date, Author, Keywords and set the status to Enabled or Disabled. By default, all new posts are Enabled.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-page-title',
					intro: "Posts are identified by Title and can be sorted in alphabetical order by clicking on the \"Post Title\" column head. Click on the actual post title to edit the post.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-creation-date',
					intro: 'The time and date the post was created is displayed here to help you manage posts and sort by the date they were entered.',
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-last-modified',
					intro: "The time and date the post was last edited.  This is helpful in tracking recent changes.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-status',
					intro: "Determine if a post is enabled or disabled. Disabled post cannot be viewed even if they are linked via the navigation and are typically a draft of a post being created.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-delete',
					intro: "Select this icon to delete a post.  A warning prompt will be displayed as this cannot be undone.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-preview',
					intro: "Click on this icon to open a new tab with a preview of the post. Preview will display posts that are both enabled or disabled.",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				},
				{
					element: '.js-intro-edit',
					intro: "Click on this icon to go to the post editor where the selected post can be updated. ",
					position: 'bottom',
					tooltipClass: "introjs-fullwidth"
				}
			]
		});
		$('.js-trigger-intro').on('click',function () {
			//TODO need to write a better script
			introBlogTour.start();
		});

		if (RegExp('multipage', 'gi').test(window.location.search)) {
			introBlogTour.start();
		}
	};

}(window.cny = window.cny || {}, jQuery));
